import SpacedTextInput, {
  SpacedAutocompleteInput,
} from "components/common/ui/SpacedTextInput";
import React, {
  ChangeEvent,
  FC,
  memo,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Grid, Tooltip } from "@mui/material";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircleIconButton from "components/common/ui/CircleIconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  defaultMenuItemDisplay,
  DisplayMenuItem,
  MenuContentActions,
} from "models/menu/menuContentsReducer";
import { MenuItemMainEditBoxProps } from "components/menu/ui/MenuItemMainEditBox";
import AdaptiveRowHeader from "components/common/ui/AdaptiveRowHeader";
import { getFoodItemOptionMaps } from "utils/food/foodItemOptionDisplayUtils";

const SideItemTitle = styled(Typography)`
  display: inline-block;
  width: fit-content;
  white-space: nowrap;
  font-weight: 400;
  color: #a0aab4;
`;

interface MenuItemEditBoxProps extends MenuItemMainEditBoxProps {
  displayItemSideUUID?: string;
  label?: string;
  onDelete?: VoidFunction;
}

const MenuItemEditBox: FC<MenuItemEditBoxProps> = ({
  foodItemOptions,
  displayMenuSectionUUID,
  displayItemMainUUID,
  displayItemSideUUID,
  menuContents,
  dispatchMenuContents,
  label,
  onDelete,
}) => {
  const [foodItemOptionToIdMap, setFoodItemOptionToIdMap] = useState<
    Record<string, number>
  >({ "": 0 });
  const [idToFoodItemOptionMap, setIdToFoodItemOptionMap] = useState<
    Map<number, string>
  >(new Map([[0, ""]]));
  const [selectedFoodDisplay, setSelectedFoodDisplay] = useState<
    string | null
  >();
  const [menuItemDisplay, setMenuItemDisplay] = useState<DisplayMenuItem>(
    defaultMenuItemDisplay(),
  );

  /**
   * Use effect to sync the menu item input fields
   */
  useEffect(() => {
    if (displayItemSideUUID) {
      setMenuItemDisplay(
        menuContents[displayMenuSectionUUID].items[displayItemMainUUID].sides[
          displayItemSideUUID
        ],
      );
      setSelectedFoodDisplay(
        idToFoodItemOptionMap.get(
          menuContents[displayMenuSectionUUID].items[displayItemMainUUID].sides[
            displayItemSideUUID
          ].food_id,
        ),
      );
    } else {
      setMenuItemDisplay(
        menuContents[displayMenuSectionUUID].items[displayItemMainUUID],
      );
      setSelectedFoodDisplay(
        idToFoodItemOptionMap.get(
          menuContents[displayMenuSectionUUID].items[displayItemMainUUID]
            .food_id,
        ),
      );
    }
  }, [
    displayItemMainUUID,
    displayItemSideUUID,
    displayMenuSectionUUID,
    idToFoodItemOptionMap,
    menuContents,
  ]);

  /**
   * Use effect to sync the food item option names
   */
  useEffect(() => {
    const { foodItemOptionNamesToIdsMap, idsToFoodItemOptionNamesMap } =
      getFoodItemOptionMaps(foodItemOptions);
    setFoodItemOptionToIdMap(foodItemOptionNamesToIdsMap);
    setIdToFoodItemOptionMap(idsToFoodItemOptionNamesMap);
  }, [foodItemOptions]);

  /**
   * Update the menu item
   */
  const handleUpdateMenuItem = useCallback(() => {
    if (displayItemSideUUID) {
      dispatchMenuContents({
        type: MenuContentActions.UPDATE_SIDE,
        sectionUUID: displayMenuSectionUUID,
        itemMainUUID: displayItemMainUUID,
        itemSideUUID: displayItemSideUUID,
        newItemFields: menuItemDisplay,
      });
    } else {
      dispatchMenuContents({
        type: MenuContentActions.UPDATE_ITEM,
        sectionUUID: displayMenuSectionUUID,
        itemMainUUID: displayItemMainUUID,
        newItemFields: menuItemDisplay,
      });
    }
  }, [
    dispatchMenuContents,
    displayItemMainUUID,
    displayItemSideUUID,
    displayMenuSectionUUID,
    menuItemDisplay,
  ]);

  /**
   * Handle menu item option fields changes
   */
  const handleMenuItemChange = useCallback(
    (_: SyntheticEvent, newValue: string | null) => {
      setSelectedFoodDisplay(newValue);
      setMenuItemDisplay({
        ...menuItemDisplay,
        food_id: newValue !== null ? foodItemOptionToIdMap[newValue] : 0,
      });
    },
    [foodItemOptionToIdMap, menuItemDisplay],
  );

  /**
   * Handle menu item text input fields changes
   */
  const handleMenuItemInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const keyInput: string = event.target.name;
      let valueInput: number | string = event.target.value;
      if (!valueInput.endsWith(".") && keyInput !== "display_name") {
        valueInput = Number(valueInput) || valueInput;
      }
      setMenuItemDisplay({
        ...menuItemDisplay,
        [event.target.name]: valueInput,
      });
    },
    [menuItemDisplay],
  );

  return (
    <Box display="flex" justifyContent="flex-end">
      {label ? (
        <AdaptiveRowHeader switchWidthInPixel={900}>
          <Tooltip title="Remove this side">
            <CircleIconButton
              positive
              danger
              sizepx={30}
              onClick={onDelete ?? (() => {})}
            >
              <CloseIcon />
            </CircleIconButton>
          </Tooltip>
          <Box padding={1}>
            <SideItemTitle display="inline-block">{label}</SideItemTitle>
          </Box>
        </AdaptiveRowHeader>
      ) : null}
      <Grid container spacing={1} onBlur={handleUpdateMenuItem}>
        <Grid item xs={12} md={5}>
          <SpacedAutocompleteInput
            options={Object.keys(foodItemOptionToIdMap)}
            label="Select food"
            value={selectedFoodDisplay ?? ""}
            onChange={handleMenuItemChange}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <SpacedTextInput
            type="text"
            label="Display name"
            name="display_name"
            value={menuItemDisplay.display_name ?? ""}
            onChange={handleMenuItemInputChange}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SpacedTextInput
            type="number"
            label="Production"
            name="cap"
            value={menuItemDisplay.cap ?? ""}
            onChange={handleMenuItemInputChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default memo(MenuItemEditBox);
