import { RootState } from "store/index";

const editingMenuLoop = (state: RootState) => state.menuLoopDetail.menuLoop;

const isLoading = (state: RootState) => state.menuLoopDetail.isLoading;

const error = (state: RootState) => state.menuLoopDetail.error;

export default {
  editingMenuLoop,
  isLoading,
  error,
};
