import styled from "styled-components";
import CircleIconButton from "components/common/ui/CircleIconButton";

const SoftSquareIconButton = styled(CircleIconButton).withConfig({
  shouldForwardProp: (prop) =>
    !["positive", "natural", "danger", "ok"].includes(prop),
})`
  border-radius: 0.3rem;
`;

export default SoftSquareIconButton;
