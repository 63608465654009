import styled, { css } from "styled-components";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";

export interface CircleIconButtonProps extends IconButtonProps {
  sizepx?: number;
  positive?: boolean | undefined;
  natural?: boolean | undefined;
  danger?: boolean | undefined;
  ok?: boolean | undefined;
}

const CircleIconButton = styled(IconButton).withConfig({
  shouldForwardProp: (prop) =>
    !["positive", "natural", "danger", "ok"].includes(prop),
})<CircleIconButtonProps>`
  border-radius: 50%;
  height: ${(props) => props.sizepx ?? 40}px;
  width: ${(props) => props.sizepx ?? 40}px;
  align-content: center;

  ${(props) => {
    let background = css`rgb(244, 244, 244)`;
    let color = css`rgb(120, 203, 255)`;
    if (props.natural) {
      color = css`rgb(200, 200, 200)`;
      background = css`"none"`;
    }
    if (props.danger) {
      color = css`rgb(246, 97, 97)`;
    } else if (props.ok) {
      color = css`rgb(147, 196, 125)`;
    }
    if (props.positive) {
      const middle = background;
      background = color;
      color = middle;
    }

    return css`
      background: ${background};
      border: 0.15rem solid ${background};
      color: ${color};

      &:hover {
        background: #ffcf84;
        border: 0.15rem solid #ffcf84;
        color: white;
      }
    `;
  }}
`;

export default CircleIconButton;
