import React, { FC, useCallback } from "react";
import EditSection from "components/common/ui/EditSection";
import CommonButton from "components/common/ui/CommonButton";
import MenuLoopBasicInfoEditSection from "components/menu/ui/menuLoop/MenuLoopBasicInfoEditSection";
import MenusInLoopEditSection from "components/menu/ui/menuLoop/MenusInLoopEditSection";
import {
  getMenuLoopCreateDeepFromDisplay,
  getMenuLoopUpdateDeepFromDisplay,
} from "utils/menu/menuLoop/menuLoopToDeepUpdate";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "hooks/useReduxStore";
import menuLoopDetailSelector from "store/selectors/menu/menuLoopDetailSelector";
import jspPlatformApi from "client/portals";
import { usePromptSnackBar } from "components/common/ui/PromptSnackBarProvider";

interface MenuLoopEditFormsProps {}

const MenuLoopEditForms: FC<MenuLoopEditFormsProps> = () => {
  const promptSnackBar = usePromptSnackBar();
  const navigate = useNavigate();
  const { menuLoopId } = useParams();
  const editingMenuLoop = useAppSelector(
    menuLoopDetailSelector.editingMenuLoop,
  );

  const onCreate = useCallback(() => {
    if (editingMenuLoop) {
      jspPlatformApi()
        .menu.createMenuLoopMenuLoopPost({
          requestBody: getMenuLoopCreateDeepFromDisplay(editingMenuLoop),
        })
        .then(() => {
          promptSnackBar.setPromptProps({
            isOpen: true,
            message: "Menu loop creation succeed",
            severity: "success",
            autoHeightDuration: 5000,
          });
          navigate(-1);
        })
        .catch(() => {});
    }
  }, [editingMenuLoop, navigate, promptSnackBar]);

  const handleSaveClick = useCallback(() => {
    if (editingMenuLoop) {
      if (Number(menuLoopId)) {
        // Update
        jspPlatformApi()
          .menu.updateMenuLoopMenuLoopMenuLoopIdPatch({
            menuLoopId: Number(menuLoopId),
            requestBody: getMenuLoopUpdateDeepFromDisplay(editingMenuLoop),
          })
          .then(() => {
            promptSnackBar.setPromptProps({
              isOpen: true,
              message: "Menu loop updating succeed",
              severity: "success",
              autoHeightDuration: 5000,
            });
            navigate(-1);
          })
          .catch(() => {});
      } else {
        // Create
        onCreate();
      }
    }
  }, [editingMenuLoop, menuLoopId, navigate, onCreate, promptSnackBar]);

  return (
    <>
      <EditSection>
        <CommonButton text="Save" positive onClick={handleSaveClick} />
      </EditSection>
      <EditSection header="Menu Loop Basic Information">
        <MenuLoopBasicInfoEditSection />
      </EditSection>
      <EditSection header="Menus in Loop">
        <MenusInLoopEditSection />
      </EditSection>
      <EditSection />
    </>
  );
};

export default MenuLoopEditForms;
