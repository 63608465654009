import React, { FC, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import NavButton from "components/navBar/ui/NavButton";
import NavListButton from "components/navBar/ui/NavListButton";
import Routes from "models/routes";
import NavExpandingMenu, {
  NavExpandingMenuProps,
} from "components/navBar/ui/NavExpandingMenu";
import NavDrawerNestedList from "components/navBar/ui/NavDrawerNestedList";
import { useTranslation } from "react-i18next";

interface TopAppBarProps {}

const HeaderToolbar = styled(Box)`
  padding: 0;
`;

const JspToolbar = styled(Toolbar)`
  display: flex;
  background: rgb(120, 203, 255);
  color: #fff;
  min-height: 56px;
`;

const appName = "Chef Jasper";
const drawerWidth = 240;

const navItems: Array<NavExpandingMenuProps> = [
  {
    menuDisplayName: "User",
    options: [{ displayName: "User", path: Routes.USER_PROFILE }],
  },
  {
    menuDisplayName: "Food",
    options: [
      { displayName: "Ingredient", path: Routes.FOOD_INGREDIENT },
      { displayName: "Recipe", path: Routes.FOOD_RECIPE },
      // { displayName: "Nutrition", path: Routes.NUTRITION },
    ],
  },
  {
    menuDisplayName: "Menu",
    options: [
      { displayName: "Meal menus", path: Routes.MENU },
      { displayName: "Menu loop", path: Routes.MENU_LOOP },
      { displayName: "Published menus", path: Routes.MENU_PUBLISHED },
    ],
  },
  {
    menuDisplayName: "Ordering",
    options: [{ displayName: "Ordering", path: Routes.COMING_SOON }],
  },
  {
    menuDisplayName: "Inventory",
    options: [
      { displayName: "Stock", path: Routes.INVENTORY },
      { displayName: "Purchasing", path: Routes.INVENTORY_PURCHASING },
    ],
  },
];

const TopAppBar: FC<TopAppBarProps> = () => {
  const { t } = useTranslation();
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  const handleDrawerToggle = (): void => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      {/* <Typography variant="h6" sx={{ my: 2 }}>*/}
      {/*  Jasper*/}
      {/* </Typography>*/}
      <Divider />
      <List>
        {navItems.map((item) =>
          item.options.length === 1 ? (
            <NavListButton
              key={item.menuDisplayName}
              displayName={t(item.options[0].displayName)}
              path={item.options[0].path}
              onClick={handleDrawerToggle}
            />
          ) : (
            <NavDrawerNestedList
              key={item.menuDisplayName}
              menuDisplayName={t(item.menuDisplayName)}
              options={item.options}
              onClick={handleDrawerToggle}
            />
          ),
        )}
      </List>
    </Box>
  );

  return (
    <HeaderToolbar>
      <JspToolbar sx={{ flexGrow: 0 }}>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, display: "block" }}
        >
          {appName}
        </Typography>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          {navItems.map((item) =>
            item.options.length === 1 ? (
              <NavButton
                key={item.options[0].displayName}
                displayName={t(item.options[0].displayName)}
                path={item.options[0].path}
              />
            ) : (
              <NavExpandingMenu
                key={item.menuDisplayName}
                menuDisplayName={t(item.menuDisplayName)}
                options={item.options}
              />
            ),
          )}
        </Box>
      </JspToolbar>
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
    </HeaderToolbar>
  );
};

export default TopAppBar;
