import { FC } from "react";
import { Container, Grid } from "@mui/material";
import IngredientListTable from "components/food/ui/ingredientList/IngredientListTable";
import BottomSpacedPageHeader from "components/common/ui/BottomSpacedPageHeader";

interface IngredientListPageProps {}

const IngredientListPage: FC<IngredientListPageProps> = () => {
  return (
    <Container>
      <BottomSpacedPageHeader>Ingredients</BottomSpacedPageHeader>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <IngredientListTable />
        </Grid>
      </Grid>
    </Container>
  );
};

export default IngredientListPage;
