import {
  MenuInLoop,
  MenuInLoopCreate,
  MenuLoop,
  MenuLoopCreateDeep,
  MenuLoopUpdateDeep,
} from "client/jspPlatformExperiment";

export const getMenuListsAndALaCarteMenuIds = (
  daysPerCycle: number,
  menuLinks: MenuInLoop[],
) => {
  const dayGroupMenus = menuLinks.reduce((acc, link) => {
    acc.set(link.looping_day_index, [
      ...(acc.get(link.looping_day_index) ?? []),
      { menu_id: link.menu.id, serving_time: link.serving_time },
    ]);
    return acc;
  }, new Map<number, Array<MenuInLoopCreate>>());
  const menuLinksCreate = Array.from(
    { length: daysPerCycle },
    (_, i) => dayGroupMenus.get(i + 1) ?? [],
  );
  const menuLinksCreateALaCarte = dayGroupMenus.get(0) ?? [];
  return {
    menuLinksCreate,
    menuLinksCreateALaCarte,
  };
};

export const getMenuLoopCreateDeepFromDisplay = (
  menuLoop: MenuLoop,
): MenuLoopCreateDeep => {
  const { menuLinksCreate, menuLinksCreateALaCarte } =
    getMenuListsAndALaCarteMenuIds(
      menuLoop.days_per_cycle,
      menuLoop.menu_links,
    );
  return {
    name: menuLoop.name,
    start_date: menuLoop.start_date,
    end_date: menuLoop.end_date,
    menu_links: menuLinksCreate,
    menu_links_a_la_carte: menuLinksCreateALaCarte,
  };
};

export const getMenuLoopUpdateDeepFromDisplay = (
  menuLoop: MenuLoop,
): MenuLoopUpdateDeep => {
  const { menuLinksCreate, menuLinksCreateALaCarte } =
    getMenuListsAndALaCarteMenuIds(
      menuLoop.days_per_cycle,
      menuLoop.menu_links,
    );
  return {
    name: menuLoop.name,
    start_date: menuLoop.start_date,
    end_date: menuLoop.end_date,
    menu_links: menuLinksCreate,
    menu_links_a_la_carte: menuLinksCreateALaCarte,
  };
};
