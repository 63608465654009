import React, { FC, useCallback, useState } from "react";
import { Container, Grid } from "@mui/material";
import BottomSpacedPageHeader from "components/common/ui/BottomSpacedPageHeader";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import styled from "styled-components";
import dayjs, { Dayjs } from "dayjs";
import jspPlatformApi from "client/portals";
import { Menu } from "client/jspPlatformExperiment";
import Typography from "@mui/material/Typography";
import MenuViewBox from "components/menu/ui/common/MenuViewBox";

const StyledDateCalendar = styled(DateCalendar)`
  display: flex;
  align-self: flex-start;
  border-radius: 0.5rem;
  border: 0.15rem solid rgb(244, 244, 244);
  background: rgb(244, 244, 244);

  .MuiPickersToolbar-root {
    background-color: rgb(120, 203, 255);
  }

  .MuiPickersDay-today {
    border: 0.1rem solid #ffcf84;
  }

  .Mui-selected {
    background-color: rgb(120, 203, 255);

    &:hover {
      background-color: rgb(120, 203, 255);
    }

    &:focus {
      background-color: rgb(120, 203, 255);
    }
  }

  .MuiPickersDay-dayWithMargin {
    &:hover {
      background-color: #ffcf84;
    }
  }
`;

const SelectedDateHeader = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 100;
  padding-left: 0.5rem;
`;

interface MenuPublishPageProps {}

const MenuPublishPage: FC<MenuPublishPageProps> = () => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>();
  const [dailyMenus, setDailyMenus] = useState<Menu[]>([]);

  /**
   * Handle select date changes
   */
  const handleSelectedDateChange = useCallback((newValue: Dayjs) => {
    setSelectedDate(newValue);
    jspPlatformApi()
      .menu.getDailyMenusMenuDailyQueryDateGet({
        queryDate: newValue.format("YYYY-MM-DD"),
      })
      .then((menus) => {
        setDailyMenus(menus);
      })
      .catch(() => {});
  }, []);

  return (
    <Container>
      <BottomSpacedPageHeader>Published Menus</BottomSpacedPageHeader>
      <Grid container spacing={1} columns={24}>
        <Grid item lg={7}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledDateCalendar
              views={["year", "month", "day"]}
              value={selectedDate ?? dayjs()}
              onChange={handleSelectedDateChange}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item lg={17} xs={24}>
          <SelectedDateHeader>
            {selectedDate ? selectedDate.format("dddd MMMM DD, YYYY ") : ""}
          </SelectedDateHeader>
          <Grid container>
            {dailyMenus.map((menu) => (
              <Grid key={menu.id} item md={6} xs={24}>
                <MenuViewBox menu={menu} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MenuPublishPage;
