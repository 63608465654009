import { FC } from "react";
import CommonButton from "components/common/ui/CommonButton";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";

interface MenuEditActionButtonsProps {
  onCreate: VoidFunction;
  onUpdate: VoidFunction;
  onDelete: VoidFunction;
}

const MenuEditActionButtons: FC<MenuEditActionButtonsProps> = ({
  onCreate,
  onUpdate,
  onDelete,
}) => {
  return (
    <Grid container>
      <Grid item xs={8}>
        <CommonButton text="Save" positive onClick={onUpdate} />
        <CommonButton text="Save As New" positive onClick={onCreate} />
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="flex-end">
          <CommonButton text="Delete" danger onClick={onDelete} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default MenuEditActionButtons;
