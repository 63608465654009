import List from "@mui/material/List";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import FoodTagSwitch from "components/food/ui/TagSwitch";
import { FoodTag, FoodTagCategory } from "client/jspPlatformExperiment";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { useAppSelector } from "hooks/useReduxStore";
import foodItemOptionsSelector from "store/selectors/food/foodItemOptionsSelector";
import { getFoodItemOptionName } from "utils/food/foodItemOptionDisplayUtils";

const StyledTagCategoryHeader = styled(Typography)`
  font-size: 1rem;
  font-weight: 200;
  padding: 8px 0 2px 0;
  color: #ccc;
`;

interface FoodItemTagEditSectionProps {
  foodTagOptions: FoodTag[];
  foodItemTags: FoodTag[];
  setFoodItemTags: Dispatch<SetStateAction<FoodTag[]>>;
  aggregatingFromFoodIds?: number[] | undefined;
  viewOnlyAllergens?: boolean;
  isDish?: boolean;
}

const FoodItemTagEditSection: FC<FoodItemTagEditSectionProps> = ({
  foodTagOptions,
  foodItemTags,
  setFoodItemTags,
  aggregatingFromFoodIds,
  viewOnlyAllergens = false,
  isDish = false,
}) => {
  const idToFoodItemOption = useAppSelector(
    foodItemOptionsSelector.idToFoodItemOptionRecords,
  );
  const [allergenSources, setAllergenSources] = useState<Map<number, string[]>>(
    new Map([]),
  );

  /**
   * Summary the allergy source food item names
   */
  useEffect(() => {
    const newAllergenSources = new Map<number, string[]>([]);
    aggregatingFromFoodIds?.forEach((foodId) => {
      const sourceFoodItem = idToFoodItemOption[foodId];
      if (sourceFoodItem) {
        const sourceFoodItemOptionName = getFoodItemOptionName(sourceFoodItem);
        sourceFoodItem?.food_tags
          ?.filter((foodTag) =>
            foodTag.tag_categories.includes(FoodTagCategory.ALLERGEN),
          )
          .forEach((foodTag) => {
            const sourceIngNameList = newAllergenSources.get(foodTag.id);
            if (sourceIngNameList) {
              if (!sourceIngNameList.includes(sourceFoodItemOptionName)) {
                sourceIngNameList.push(sourceFoodItemOptionName);
              }
            } else {
              newAllergenSources.set(foodTag.id, [sourceFoodItemOptionName]);
            }
          });
      }
    });
    setAllergenSources(newAllergenSources);
  }, [idToFoodItemOption, aggregatingFromFoodIds]);

  /**
   * Add/remove tag from food item tags
   */
  const handleTagChange = useCallback(
    (changeTag: FoodTag) => {
      const changeTagIdx: number = foodItemTags.findIndex(
        (foodItemTag) => foodItemTag.id === changeTag.id,
      );
      if (changeTagIdx !== -1) {
        setFoodItemTags(
          foodItemTags.filter((foodItemTag) => foodItemTag.id !== changeTag.id),
        );
      } else {
        setFoodItemTags([...foodItemTags.slice(), changeTag]);
      }
    },
    [foodItemTags, setFoodItemTags],
  );

  /**
   * Get the default state by checking if the tag is in the food tags
   */
  const getDefaultState = useCallback(
    (checkTag: FoodTag) => {
      return (
        foodItemTags.find((foodItemTag) => foodItemTag.id === checkTag.id) !==
        undefined
      );
    },
    [foodItemTags],
  );

  /**
   * Get the allergy source food item names
   */
  const getAllergenSourceFoodNames = useCallback(
    (tag: FoodTag) => {
      let description = "";
      const allergenSourcesIng = allergenSources.get(tag.id);
      if (allergenSourcesIng) {
        allergenSourcesIng.forEach((foodOptionName) => {
          if (description !== "") {
            description = `${description} | ${foodOptionName}`;
          } else {
            description = `${foodOptionName}`;
          }
        });
      }
      return description;
    },
    [allergenSources],
  );

  return (
    <>
      <List>
        <StyledTagCategoryHeader>
          Allergies {viewOnlyAllergens ? `(view only)` : null}
        </StyledTagCategoryHeader>
        {foodTagOptions
          .filter(
            (foodTagOption) =>
              foodTagOption.tag_categories.includes(FoodTagCategory.ALLERGEN) &&
              (!viewOnlyAllergens || getDefaultState(foodTagOption)),
          )
          .map((foodTagOption) => {
            return (
              <FoodTagSwitch
                key={`foodTag${foodTagOption.id}`}
                foodTag={foodTagOption}
                onChange={handleTagChange}
                defaultState={getDefaultState(foodTagOption)}
                hints={getAllergenSourceFoodNames(foodTagOption)}
                viewOnly={viewOnlyAllergens}
              />
            );
          })}
      </List>
      {isDish ? (
        <>
          <List>
            <StyledTagCategoryHeader>
              Suitable Diet Types
            </StyledTagCategoryHeader>
            {foodTagOptions
              .filter(
                (foodTagOption) =>
                  foodTagOption.tag_categories.includes(
                    FoodTagCategory.DIET_TYPE,
                  ) &&
                  !foodTagOption.tag_categories.includes(
                    FoodTagCategory.ALLERGEN,
                  ),
              )
              .map((foodTagOption) => {
                return (
                  <FoodTagSwitch
                    key={`foodTag${foodTagOption.id}`}
                    foodTag={foodTagOption}
                    onChange={handleTagChange}
                    defaultState={getDefaultState(foodTagOption)}
                  />
                );
              })}
          </List>
          <List>
            <StyledTagCategoryHeader>
              Suitable Food Textures
            </StyledTagCategoryHeader>
            {foodTagOptions
              .filter(
                (foodTagOption) =>
                  foodTagOption.tag_categories.includes(
                    FoodTagCategory.FOOD_TEXTURE,
                  ) &&
                  !(
                    foodTagOption.tag_categories.includes(
                      FoodTagCategory.ALLERGEN,
                    ) ||
                    foodTagOption.tag_categories.includes(
                      FoodTagCategory.DIET_TYPE,
                    )
                  ),
              )
              .map((foodTagOption) => {
                return (
                  <FoodTagSwitch
                    key={`foodTag${foodTagOption.id}`}
                    foodTag={foodTagOption}
                    onChange={handleTagChange}
                    defaultState={getDefaultState(foodTagOption)}
                  />
                );
              })}
          </List>
          <List>
            <StyledTagCategoryHeader>
              Suitable Liquid Thickness
            </StyledTagCategoryHeader>
            {foodTagOptions
              .filter(
                (foodTagOption) =>
                  foodTagOption.tag_categories.includes(
                    FoodTagCategory.LIQUID_THICKNESS,
                  ) &&
                  !(
                    foodTagOption.tag_categories.includes(
                      FoodTagCategory.ALLERGEN,
                    ) ||
                    foodTagOption.tag_categories.includes(
                      FoodTagCategory.DIET_TYPE,
                    ) ||
                    foodTagOption.tag_categories.includes(
                      FoodTagCategory.FOOD_TEXTURE,
                    )
                  ),
              )
              .map((foodTagOption) => {
                return (
                  <FoodTagSwitch
                    key={`foodTag${foodTagOption.id}`}
                    foodTag={foodTagOption}
                    onChange={handleTagChange}
                    defaultState={getDefaultState(foodTagOption)}
                  />
                );
              })}
          </List>
        </>
      ) : null}
    </>
  );
};

export default FoodItemTagEditSection;
