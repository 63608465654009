import React, { FC, lazy } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import RootLayout from "components/common/ui/RootLayout";
import Routes from "models/routes";
import IngredientListPage from "components/food/ui/ingredientList/IngredientListPage";
import AuthGuard from "components/login/ui/AuthGuard";
import AuthProvider from "components/login/ui/AuthProvider";
import MenuEditPage from "components/menu/ui/MenuEditPage";
import RecipeListPage from "components/food/ui/recipeList/recipeListPage";
import MenuListPage from "components/menu/ui/menuList/menuListPage";
import MenuPublishPage from "components/menu/ui/menuPublishment/menuPublishPage";
import PromptSnackBarProvider from "components/common/ui/PromptSnackBarProvider";
import IngredientStockListPage from "components/inventory/ui/stockLevelList/IngredientStockListPage";
import FoodStockDetailPage from "components/inventory/ui/stockDetails/FoodStockDetailPage";
import FoodPurchasingPage from "components/inventory/ui/purchasing/FoodPurchasingPage";
import ReplenishBySupplierOrderCSVPage from "components/inventory/ui/replenishment/ReplenishBySupplierOrderCSVPage";
import MenuLoopEditPage from "components/menu/ui/menuLoop/MenuLoopEditPage";
import MenuLoopListPage from "components/menu/ui/menuLoopList/menuLoopListPage";
import LoginContainer from "./login/ui/LoginContainer";
import RecipeContainer from "./food/ui/RecipeContainer";
import DefaultErrorPage from "./error/DefaultError";
import UserProfileContainer from "./user/ui/UserProfileContainer";
import NutritionContainer from "./food/ui/NutritionContainer";
import RecipeViewLayout from "./food/ui/RevipeViewLayout";

const IngredientEditPage = lazy(
  () =>
    import(
      /* webpackChunkName: "IngredientEditPage" */
      "components/food/ui/IngredientEditPage"
    ),
);

const RecipeEditPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RecipeEditPage" */
      "components/food/ui/recipe/RecipeEditPage"
    ),
);

interface AppRouterProps {}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<DefaultErrorPage />}>
      <Route path={Routes.LOGIN} element={<LoginContainer />} />
      <Route
        element={
          <AuthGuard>
            <RootLayout />
          </AuthGuard>
        }
      >
        <Route path={Routes.HOME} element={<h1>Hello</h1>} />
        <Route path={Routes.COMING_SOON} element={<h1>Coming soon ... </h1>} />
        <Route path={Routes.FOOD} element={<IngredientListPage />} />
        <Route path={Routes.FOOD_INGREDIENT} element={<IngredientListPage />} />
        <Route
          path={`${Routes.FOOD_INGREDIENT_EDIT}/:foodId`}
          element={<IngredientEditPage />}
        />
        <Route
          path={Routes.FOOD_INGREDIENT_CREATE}
          element={<IngredientEditPage />}
        />
        <Route path={Routes.FOOD_RECIPE} element={<RecipeListPage />} />
        <Route
          path={`${Routes.FOOD_RECIPE_EDIT}/:foodId/:recipeId`}
          element={<RecipeEditPage />}
        />
        <Route path={Routes.FOOD_RECIPE_CREATE} element={<RecipeEditPage />} />
        <Route path={Routes.MENU} element={<MenuListPage />} />
        <Route path={Routes.MENU_PUBLISHED} element={<MenuPublishPage />} />
        <Route
          path={`${Routes.MENU_EDIT}/:menuId`}
          element={<MenuEditPage />}
        />
        <Route path={Routes.MENU_CREATE} element={<MenuEditPage />} />
        <Route path={Routes.MENU_LOOP} element={<MenuLoopListPage />} />
        <Route
          path={`${Routes.MENU_LOOP_CREATE}`}
          element={<MenuLoopEditPage />}
        />
        <Route
          path={`${Routes.MENU_LOOP_EDIT}/:menuLoopId`}
          element={<MenuLoopEditPage />}
        />
        <Route path={Routes.INVENTORY} element={<IngredientStockListPage />} />
        <Route
          path={`${Routes.INVENTORY_DETAILS}/:foodId`}
          element={<FoodStockDetailPage />}
        />
        <Route
          path={Routes.INVENTORY_EDIT_CSV}
          element={<ReplenishBySupplierOrderCSVPage />}
        />
        <Route
          path={Routes.INVENTORY_PURCHASING}
          element={<FoodPurchasingPage />}
        />
        <Route path="/recipe" element={<RecipeViewLayout />}>
          <Route path={Routes.NUTRITION} element={<NutritionContainer />} />
          <Route path={Routes.RECIPE_GRAPH} element={<RecipeContainer />} />
        </Route>
        {/* <Route path="recipe/nutrition" element={<NutritionContainer/>}/>*/}
        <Route path={Routes.USER_PROFILE} element={<UserProfileContainer />} />
      </Route>
    </Route>,
  ),
);

const AppRouter: FC<AppRouterProps> = () => {
  return (
    <PromptSnackBarProvider>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </PromptSnackBarProvider>
  );
};

export default AppRouter;
