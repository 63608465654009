enum Routes {
  HOME = "/",
  COMING_SOON = "/coming_soon",
  LOGIN = "/login",
  USER_PROFILE = "/user/profile",
  FOOD = "/food",
  FOOD_INGREDIENT = "/food/ingredient/list",
  FOOD_INGREDIENT_EDIT = "/food/ingredient/edit",
  FOOD_INGREDIENT_CREATE = "/food/ingredient/create",
  FOOD_RECIPE = "/food/recipe/list",
  FOOD_RECIPE_EDIT = "/food/recipe/edit",
  FOOD_RECIPE_CREATE = "/food/recipe/create",
  NUTRITION = "/recipe/nutrition",
  RECIPE_GRAPH = "/recipe/graph",
  MENU = "/menu/list",
  MENU_PUBLISHED = "/menu/published",
  MENU_EDIT = "/menu/edit",
  MENU_CREATE = "/menu/create",
  MENU_LOOP = "/menu/loop/list",
  MENU_LOOP_EDIT = "/menu/loop/edit",
  MENU_LOOP_CREATE = "/menu/loop/create",
  ORDERING = "/ordering",
  INVENTORY = "/inventory/list",
  INVENTORY_EDIT_CSV = "/inventory/edit/csv",
  INVENTORY_DETAILS = "/inventory/details",
  INVENTORY_PURCHASING = "/inventory/purchasing",
}

export default Routes;
