import { Menu } from "client/jspPlatformExperiment";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import jspPlatformApi from "client/portals";
import { isApiError, isErrorResponseBodyType } from "models/utils/apiUtils";
import { isMenuArray } from "models/menu/menu";

interface MenuOptionsState {
  data: Record<number, Menu>;
  isLoading: boolean;
  error: string | null | undefined;
}

const initialState: MenuOptionsState = {
  data: [],
  isLoading: false,
  error: null,
};

const fetchAllMenuOptions = createAsyncThunk(
  "menuOptions/fetchAllMenuOptions",
  async (_: null | undefined, { rejectWithValue }) =>
    jspPlatformApi()
      .menu.searchMenuMenuMealSearchGet()
      .then((result) => {
        if (isMenuArray(result.data)) {
          return result.data;
        }
        return [];
      })
      .catch((error) => {
        let errMsg: string;
        if (isApiError(error) && isErrorResponseBodyType(error.body)) {
          errMsg = error.body.detail;
        } else {
          errMsg = "Unknown error!";
        }
        return rejectWithValue(errMsg);
      }),
);

const setMenuOptions = (
  state: MenuOptionsState,
  action: PayloadAction<Menu[] | null>,
): MenuOptionsState => {
  return {
    ...state,
    data: {
      ...state.data,
      ...action.payload?.reduce<Record<number, Menu>>((acc, newMenu) => {
        acc[newMenu.id] = newMenu;
        return acc;
      }, {}),
    },
  };
};

export const menuOptionsSlice = createSlice({
  name: "menuOptionsSlice",
  initialState,
  reducers: {
    setMenuOptions,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllMenuOptions.pending, (state) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(fetchAllMenuOptions.fulfilled, (state, action) => {
      return {
        ...state,
        ...setMenuOptions(state, action),
        isLoading: false,
      };
    });
    builder.addCase(fetchAllMenuOptions.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload as string,
      };
    });
  },
});

export const actions = {
  ...menuOptionsSlice.actions,
  fetchAllMenuOptions,
};

export default menuOptionsSlice.reducer;
