import { RootState } from "store/index";

const idToMenuOptionRecords = (state: RootState) => {
  return state.menuOptions.data;
};

const menuOptions = (state: RootState) => {
  return Object.values(state.menuOptions.data);
};

export default {
  idToMenuOptionRecords,
  menuOptions,
};
