import { Menu, MenuBasic } from "client/jspPlatformExperiment";
import { isMenu } from "models/menu/menu";

/**
 * Get the menu option name shown in drop-down menu most of the time
 * @param menu The mwnu basic or menu object
 */
export const getMenuOptionName = (menu: MenuBasic | Menu): string => {
  const beginningString = `${menu.name ?? ""} - ${menu.id}`;

  if (!isMenu(menu) || (isMenu(menu) && !!menu.serving_datetime)) {
    return beginningString;
  }
  return `${beginningString} - [TEMPLATE]`;
};

/**
 * Get the menu option maps to use in multiple components
 * @param menuOptions The array of menus or menu basic objects
 */
export const getMenuOptionMaps = (
  menuOptions: Array<MenuBasic | Menu>,
): {
  menuOptionNamesToIdsMap: Record<string, number>;
  idsToMenuOptionNamesMap: Map<number, string>;
  idsToMenuNamesMap: Map<number, string>;
} => {
  const menuOptionNamesToIdsMap: Record<string, number> = { "": 0 };
  const idsToMenuOptionNamesMap: Map<number, string> = new Map([[0, ""]]);
  const idsToMenuNamesMap: Map<number, string> = new Map([[0, ""]]);
  menuOptions.forEach((menu) => {
    const menuOptionName = getMenuOptionName(menu);
    menuOptionNamesToIdsMap[menuOptionName] = menu.id;
    idsToMenuOptionNamesMap.set(menu.id, menuOptionName);
    idsToMenuNamesMap.set(menu.id, menu.name ?? "");
  });
  return {
    menuOptionNamesToIdsMap,
    idsToMenuOptionNamesMap,
    idsToMenuNamesMap,
  };
};
