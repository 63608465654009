import { FC, useEffect } from "react";
import PageHeader from "components/common/ui/PageHeader";
import { Container } from "@mui/material";
import MenuLoopEditForms from "components/menu/ui/menuLoop/MenuLoopEditForms";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/useReduxStore";
import { actions as menuLoopActions } from "store/slices/menu/menuLoopDetailSlice";
import editingMenuLoopSelector from "store/selectors/menu/menuLoopDetailSelector";
import { actions as menuOptionsActions } from "store/slices/menu/menuOptionsSlice";

interface MenuLoopEditPageProps {}

const MenuLoopEditPage: FC<MenuLoopEditPageProps> = () => {
  const { menuLoopId } = useParams();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(editingMenuLoopSelector.isLoading);

  useEffect(() => {
    const editingMenuLoopId = Number(menuLoopId);
    if (menuLoopId) {
      dispatch(menuLoopActions.fetchAMenuLoop(editingMenuLoopId));
    }
  }, [dispatch, menuLoopId]);

  /**
   * Clear menu loop details on unmount
   */
  useEffect(() => {
    return () => {
      dispatch(menuLoopActions.setMenuLoopDetail(null));
    };
  }, [dispatch]);

  /**
   * Make sure menuOptions is the latest
   */
  useEffect(() => {
    dispatch(menuOptionsActions.fetchAllMenuOptions());
  }, [dispatch]);

  return (
    <Container>
      <PageHeader>Menu Loop Details</PageHeader>
      {isLoading ? <div>Loading...</div> : <MenuLoopEditForms />}
    </Container>
  );
};

export default MenuLoopEditPage;
