import { FC } from "react";
import { Container, Grid } from "@mui/material";
import BottomSpacedPageHeader from "components/common/ui/BottomSpacedPageHeader";
import MenuLoopListTable from "components/menu/ui/menuLoopList/menuLoopListTable";

interface MenuLoopListPageProps {}

const MenuLoopListPage: FC<MenuLoopListPageProps> = () => {
  return (
    <Container>
      <BottomSpacedPageHeader>Menu Loops</BottomSpacedPageHeader>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MenuLoopListTable />
        </Grid>
      </Grid>
    </Container>
  );
};

export default MenuLoopListPage;
