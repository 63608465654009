import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MenuLoop } from "client/jspPlatformExperiment";
import jspPlatformApi from "client/portals";
import { isApiError, isErrorResponseBodyType } from "models/utils/apiUtils";

interface MenuLoopDetailStateProps {
  menuLoop: MenuLoop;
  isLoading: boolean;
  error: string | null | undefined;
}

const initialState: MenuLoopDetailStateProps = {
  menuLoop: {
    id: 0,
    name: "",
    days_per_cycle: 7,
    start_date: "",
    end_date: "",
    menu_links: [],
  },
  isLoading: false,
  error: null,
};

/** Return value of menu loop data from the Jasper Platform API call */
const fetchAMenuLoop = createAsyncThunk(
  "menuLoopDetail/fetchAMenuLoop",
  async (fetchingMenuLoopId: number, { rejectWithValue }) =>
    jspPlatformApi()
      .menu.getMenuLoopMenuLoopMenuLoopIdGet({
        menuLoopId: fetchingMenuLoopId,
      })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        let errMsg: string;
        if (isApiError(error) && isErrorResponseBodyType(error.body)) {
          errMsg = error.body.detail;
        } else {
          errMsg = "Unknown error!";
        }
        return rejectWithValue(errMsg);
      }),
);

const setMenuLoopDetail = (
  state: MenuLoopDetailStateProps,
  action: PayloadAction<MenuLoop | null>,
): MenuLoopDetailStateProps => {
  if (action.payload) {
    return { ...state, menuLoop: action.payload };
  }
  return initialState;
};

export const menuLoopDetailSlice = createSlice({
  name: "menuDetailSlice",
  initialState,
  reducers: {
    setMenuLoopDetail,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAMenuLoop.pending, (state) => {
      return { ...state, isLoading: true };
    });
    builder.addCase(fetchAMenuLoop.fulfilled, (state, action) => {
      return {
        ...state,
        ...setMenuLoopDetail(state, action),
        isLoading: false,
      };
    });
    builder.addCase(fetchAMenuLoop.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload as string,
      };
    });
  },
});

export const actions = { ...menuLoopDetailSlice.actions, fetchAMenuLoop };

export default menuLoopDetailSlice.reducer;
