import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ButtonGroup, Grid } from "@mui/material";
import { SpacedAutocompleteInput } from "components/common/ui/SpacedTextInput";
import CircleIconButton from "components/common/ui/CircleIconButton";
import MenuViewBox from "components/menu/ui/common/MenuViewBox";
import { Menu } from "client/jspPlatformExperiment";
import { useAppDispatch, useAppSelector } from "hooks/useReduxStore";
import editingMenuLoopSelector from "store/selectors/menu/menuLoopDetailSelector";
import CommonButton from "components/common/ui/CommonButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styled from "styled-components";
import dayjs from "dayjs";
import { daysPerWeek } from "models/common/constants/time";
import menuOptionsSelector from "store/selectors/menu/menuOptionsSelector";
import { getMenuOptionMaps } from "utils/menu/menuOptionDisplayUtils";
import PillButton from "components/common/ui/PillButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { actions as menuLoopActions } from "store/slices/menu/menuLoopDetailSlice";

interface SideLabelProps {
  sizepx?: number;
}

const SideLabel = styled(Box)<SideLabelProps>`
  height: ${(props) => props.sizepx ?? 40}px;
  width: ${(props) => props.sizepx ?? 40}px;
  align-content: center;
  text-align-last: center;
  vertical-align: bottom;
  display: inline-block;
`;

const ALaCartePillButton = styled(PillButton)`
  margin: 0;
  min-width: 200px;
`;

const MealTypeSwitchButton = styled(CommonButton)`
  margin: 1em 0;

  &.Mui-disabled {
    color: rgb(200, 200, 200);
    background-color: rgb(244, 244, 244);
    border: 2px solid rgb(235, 235, 235);
  }
`;

const MealTypeSwitchButtonGroup = styled(ButtonGroup)`
  margin: 3em 0 2em;
  border: 0;
`;

const MenuInLoopActionBox = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 1.5em;
  margin-bottom: -18px;
`;

interface MenusInLoopEditSectionProps {}

const MenusInLoopEditSection: FC<MenusInLoopEditSectionProps> = () => {
  const dispatch = useAppDispatch();
  const editingMenuLoop = useAppSelector(
    editingMenuLoopSelector.editingMenuLoop,
  );
  const menuOptions = useAppSelector(menuOptionsSelector.menuOptions);
  const idToMenu = useAppSelector(menuOptionsSelector.idToMenuOptionRecords);

  const [offset, setOffset] = useState<number>(
    dayjs(editingMenuLoop?.start_date ?? null).get("day") ?? 0,
  );
  const [focusDayIdx, setFocusDayIdx] = useState(1);
  const [daysPerCycle, setDaysPerCycle] = useState<number | null>(
    editingMenuLoop?.days_per_cycle ?? null,
  );
  const [displayMenus, setDisplayMenus] = useState<Menu[]>([]);
  const [displayWeekNumber, setDisplayWeekNumber] = useState(4);
  const [chosenServingTime, setChosenServingTime] = useState("12:00:00");
  const [menuOptionToIdMap, setMenuOptionToIdMap] = useState<
    Record<string, number>
  >({ "": 0 });
  const [selectedMenuId, setSelectedMenuId] = useState(0);

  /**
   * Use effect to sync the menu option names
   */
  useEffect(() => {
    const { menuOptionNamesToIdsMap } = getMenuOptionMaps(menuOptions);
    setMenuOptionToIdMap(menuOptionNamesToIdsMap);
  }, [menuOptions]);

  useEffect(() => {
    setDisplayWeekNumber(
      Math.ceil(((daysPerCycle ?? 0) + offset) / daysPerWeek),
    );
  }, [daysPerCycle, offset]);

  useEffect(() => {
    if (editingMenuLoop) {
      setOffset(dayjs(editingMenuLoop?.start_date ?? null).get("day") ?? 0);
      setDaysPerCycle(editingMenuLoop?.days_per_cycle ?? null);
      if (editingMenuLoop.menu_links) {
        setDisplayMenus(
          editingMenuLoop.menu_links
            .filter(
              (menuInLoop) =>
                menuInLoop.looping_day_index === focusDayIdx &&
                menuInLoop.serving_time === chosenServingTime,
            )
            .map((menuInLoop) => menuInLoop.menu),
        );
      }
    }
  }, [chosenServingTime, editingMenuLoop, focusDayIdx]);

  const handleSelectedMenuChange = useCallback(
    (_: SyntheticEvent, menuOption: string | null) => {
      if (menuOption) {
        setSelectedMenuId(menuOptionToIdMap[menuOption] ?? 0);
      } else {
        setSelectedMenuId(0);
      }
    },
    [menuOptionToIdMap],
  );

  const buildDayIndexCell = useCallback(
    (weekRowIdx: number, weekDayIdx: number) => {
      const dayIdxInLoop = weekRowIdx * daysPerWeek + weekDayIdx + 1 - offset;
      const menusOnDayIdx = editingMenuLoop?.menu_links
        .filter((menuInLoop) => menuInLoop.looping_day_index === dayIdxInLoop)
        .map((menuInLoop) => menuInLoop.menu);
      if (dayIdxInLoop > 0 && dayIdxInLoop <= (daysPerCycle ?? 0)) {
        return (
          <CircleIconButton
            key={`${weekRowIdx}-${weekDayIdx}`}
            sizepx={40}
            onClick={() => {
              setFocusDayIdx(dayIdxInLoop);
            }}
            positive={
              focusDayIdx === dayIdxInLoop ||
              !!(menusOnDayIdx && menusOnDayIdx.length > 0)
            }
            ok={
              !!(menusOnDayIdx && menusOnDayIdx.length > 0) &&
              focusDayIdx !== dayIdxInLoop
            }
          >
            <Typography variant="button">{dayIdxInLoop}</Typography>
          </CircleIconButton>
        );
      }

      return <SideLabel key={`${weekRowIdx}-${weekDayIdx}`} sizepx={40} />;
    },
    [daysPerCycle, editingMenuLoop?.menu_links, focusDayIdx, offset],
  );

  const handleAddMenuClick = useCallback(() => {
    if (editingMenuLoop && selectedMenuId) {
      dispatch(
        menuLoopActions.setMenuLoopDetail({
          ...editingMenuLoop,
          menu_links: [
            ...editingMenuLoop.menu_links,
            {
              serving_time: chosenServingTime,
              looping_day_index: focusDayIdx,
              menu: idToMenu[`${selectedMenuId}`],
            },
          ],
        }),
      );
    }
  }, [
    chosenServingTime,
    dispatch,
    editingMenuLoop,
    focusDayIdx,
    idToMenu,
    selectedMenuId,
  ]);

  const handleRemoveMenuClick = useCallback(
    (loopingDayIdx: number, menuId: number, servingTime: string) => () => {
      if (editingMenuLoop) {
        dispatch(
          menuLoopActions.setMenuLoopDetail({
            ...editingMenuLoop,
            menu_links: editingMenuLoop.menu_links.filter(
              (menuInLoop) =>
                !(
                  menuInLoop.looping_day_index === loopingDayIdx &&
                  menuInLoop.serving_time === servingTime &&
                  menuInLoop.menu.id === menuId
                ),
            ),
          }),
        );
      }
    },
    [dispatch, editingMenuLoop, selectedMenuId],
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={4} xl={4}>
        <Box display="flex" justifyContent="center">
          <Box sx={{ width: "336px" }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-start">
                  <SideLabel sizepx={40} />
                  {Array.from(["S", "M", "T", "W", "T", "F", "S"]).map(
                    (weekDaySymbol) => (
                      <SideLabel key={weekDaySymbol} sizepx={40}>
                        <Typography variant="overline">
                          {weekDaySymbol}
                        </Typography>
                      </SideLabel>
                    ),
                  )}
                </Box>
              </Grid>
              {Array.from(
                { length: displayWeekNumber },
                (_, index) => index,
              ).map((weekInLoopIdx) => (
                <Grid key={weekInLoopIdx} item xs={12}>
                  <SideLabel sizepx={40}>
                    <Typography variant="overline">
                      W{weekInLoopIdx + 1}
                    </Typography>
                  </SideLabel>
                  {Array.from({ length: daysPerWeek }, (_, index) => index).map(
                    (dayInWeekIdx) =>
                      buildDayIndexCell(weekInLoopIdx, dayInWeekIdx),
                  )}
                </Grid>
              ))}
              <Grid item xs={12}>
                <SideLabel sizepx={45} />
                <SideLabel sizepx={35} />
                <ALaCartePillButton
                  text="à la carte"
                  onClick={() => {
                    setFocusDayIdx(0);
                  }}
                  positive={
                    focusDayIdx === 0 ||
                    (editingMenuLoop?.menu_links
                      .filter(
                        (menuInLoop) => menuInLoop.looping_day_index === 0,
                      )
                      .map((menuInLoop) => menuInLoop.menu)?.length ?? 0) > 0
                  }
                  ok={
                    focusDayIdx !== 0 &&
                    (editingMenuLoop?.menu_links
                      .filter(
                        (menuInLoop) => menuInLoop.looping_day_index === 0,
                      )
                      .map((menuInLoop) => menuInLoop.menu)?.length ?? 0) > 0
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} lg={8} xl={8}>
        <Box
          display="flex"
          flexDirection="column-reverse"
          sx={{ height: "100%" }}
        >
          <Box display="flex" justifyContent="flex-end">
            <SpacedAutocompleteInput
              options={Object.keys(menuOptionToIdMap)}
              label="Select menu to attach"
              onChange={handleSelectedMenuChange}
            />
            <CommonButton text="Add" positive onClick={handleAddMenuClick} />
          </Box>
          <Box display="flex" justifyContent="center">
            <MealTypeSwitchButtonGroup>
              <MealTypeSwitchButton
                text="Lunch"
                positive={chosenServingTime === "12:00:00"}
                onClick={() => {
                  setChosenServingTime("12:00:00");
                }}
              />
              <MealTypeSwitchButton
                text="Dinner"
                positive={chosenServingTime === "17:00:00"}
                onClick={() => {
                  setChosenServingTime("17:00:00");
                }}
              />
            </MealTypeSwitchButtonGroup>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} container>
        {displayMenus.map((menu) => (
          <Grid
            key={`${editingMenuLoop.id}-${focusDayIdx}-${chosenServingTime}-${menu.id}`}
            item
            lg={4}
            md={6}
            xs={12}
          >
            <MenuInLoopActionBox>
              <CircleIconButton
                positive
                danger
                sizepx={36}
                onClick={handleRemoveMenuClick(
                  focusDayIdx,
                  menu.id,
                  chosenServingTime,
                )}
              >
                <DeleteIcon />
              </CircleIconButton>
            </MenuInLoopActionBox>
            <MenuViewBox menu={menu} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default MenusInLoopEditSection;
