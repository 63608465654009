import { configureStore } from "@reduxjs/toolkit";
import recipeDetailReducer from "store/slices/recipe/recipeDetailSlice";
import foodItemOptionsReducer from "store/slices/food/foodItemOptionsSlice";
import foodTagOptionsReducer from "store/slices/food/foodTagOptionsSlice";
import foodItemDetailReducer from "store/slices/foodItem/foodItemDetailSlice";
import foodStockDetailReducer from "store/slices/inventory/foodStockDetailSlice";
import menuDetailReducer from "store/slices/menu/menuDetailSlice";
import menuLoopDetailReducer from "store/slices/menu/menuLoopDetailSlice";
import dishPortionOrdersReducer from "store/slices/order/dishPortionOrdersSlice";
import foodPurchaseGuideReducer from "store/slices/inventory/foodPurchasingGuideSlice";
import restockingReducer from "store/slices/inventory/restockingSlice";
import menuOptionsReducer from "store/slices/menu/menuOptionsSlice";

const store = configureStore({
  reducer: {
    foodItemOptions: foodItemOptionsReducer,
    foodTagOptions: foodTagOptionsReducer,
    recipeDetail: recipeDetailReducer,
    foodItemDetail: foodItemDetailReducer,
    menuOptions: menuOptionsReducer,
    menuDetail: menuDetailReducer,
    menuLoopDetail: menuLoopDetailReducer,
    foodStockDetail: foodStockDetailReducer,
    dishPortionOrders: dishPortionOrdersReducer,
    foodPurchasingGuide: foodPurchaseGuideReducer,
    restocking: restockingReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
